import React from "react";

const ErrorPage = () => {
  return (
    <div>
      Error page
    </div>
  );
};

export default ErrorPage;
